import React from "react";
import { User } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPackages,
  subscribePackage,
} from "../../redux/slices/packageSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Package } from "../../models/Package";
import moment from "moment";

type BillingPlanProps = {
  user: User | null;
};

const BillingPlan: React.FC<BillingPlanProps> = ({ user }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { packages, loading, error } = useSelector(
    (state: RootState) => state.packages
  );
  const subscriptionDate = user?.activeSubscriptions?.subscriptionDate;
  const nextPaymentDate = subscriptionDate
    ? moment(subscriptionDate).add(1, "months").format("MM/DD/YYYY")
    : "N/A"; // Default to "N/A" if subscriptionDate is missing

  return (
    <div className="content-area" data-scrollbar-target="#psScrollbarInit">
      <div className="content-area-body">
        {/* <h5 className="fs-20 fw-bolder my-4 text-center">Change Password</h5> */}
        <div className="card">
          <div className="card-body">
            <div className="subscription-plan px-4 pt-4">
              {user?.activeSubscriptions ? (
                // Display this block if the user has an active subscription
                <>
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold mb-0">
                      Whatsapp Subscription & Plan:
                    </h5>
                    <button className="btn btn-sm btn-light-brand">
                      {user.activeSubscriptions.remainingMessages} More messages
                      to send
                    </button>
                  </div>
                  <div className="p-4 mb-4 d-xxl-flex d-xl-block d-md-flex align-items-center justify-content-between gap-4 border border-dashed border-gray-5 rounded-1">
                    <div className="fs-14 fw-bold text-dark mb-1">
                      Your current plan is{" "}
                      <span className="badge bg-primary text-white ms-2">
                        {user.activeSubscriptions.packageName} plan
                      </span>
                    </div>
                    <div className="fs-12 text-muted">
                      {user.activeSubscriptions.description}
                    </div>
                    {user.activeSubscriptions.packageName !== "Basic" && (
                      <button className="btn btn-sm btn-light-brand my-3 my-xxl-0 my-md-3 my-md-0">
                        {user.activeSubscriptions.TotalExtractions} More contact
                        extractions
                      </button>
                    )}
                    <div className="my-3 my-xxl-0 my-md-3 my-md-0">
                      <div className="fs-20 text-dark">
                        <span className="fw-bold">
                          ${user.activeSubscriptions.price}
                        </span>
                        / <em className="fs-11 fw-medium">Month</em>
                      </div>
                      <div className="fs-12 text-muted mt-1">
                        Billed Monthly / Next payment on {nextPaymentDate} for{" "}
                        <strong className="text-dark">
                          ${user.activeSubscriptions.price}
                        </strong>
                      </div>
                    </div>
                    <div className="hstack gap-3">
                      {/* <div className="text-danger">
            Cancel Plan
          </div> */}
                      <a href="subscribe" className="btn btn-light-brand">
                        Update Plan
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                // Display this block if the user does not have an active subscription
                <div className="p-4 mb-4 text-center">
                  <h5 className="fw-bold mb-3">No Active Subscription</h5>
                  <p className="fs-14 text-muted">
                    It seems you do not have an active subscription at the
                    moment. Please choose a plan to start sending messages.
                  </p>
                  <a href="subscribe" className="btn btn-primary">
                    View Plans
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPlan;
