import React from "react";
import { UilEye, UilEyeSlash } from "@iconscout/react-unicons";
import { Skeleton } from "@mui/material";

type AccountBalanceCardProps = {
  isLoading: boolean;
  balance: number | null;
  showBalance: boolean;
  toggleShowBalance: () => void;
  onTopUp: () => void;
};

const AccountBalanceCard: React.FC<AccountBalanceCardProps> = ({
  isLoading,
  balance,
  showBalance,
  toggleShowBalance,
  onTopUp,
}) => {
  return (
    <div className="">
      <div className="card stretch stretch-full">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center gap-3">
              <h5 className="fs-14 fw-bold text-dark mb-0">Current Balance</h5>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  style={{ width: "100px" }}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <h5 className="text-primary mb-0">
                  {showBalance
                    ? `$${Number(balance)?.toFixed(2) || "0.00"}`
                    : "******"}
                </h5>
              )}
              <span
                className="cursor-pointer text-primary"
                onClick={toggleShowBalance}
              >
                {showBalance ? <UilEyeSlash /> : <UilEye />}
              </span>
            </div>
            <button className="btn btn-primary" onClick={onTopUp}>
              Top Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBalanceCard;
