import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Dashboard from "../pages/app/Dashboard";
import ContactsCreate from "../pages/app/ContactsCreate";
import { Toaster } from "react-hot-toast";
import "../App.css";
import Settings from "../pages/app/Settings";
import ResetPassword from "../pages/app/ResetPassword";
import TestPage from "../pages/app/TestPage";
import Navigation from "./Navigation";
import { AuthProvider, useAuth } from "./auth/AuthContext";
import AddPricing from "../pages/app/AddPricing";
import PricingPlans from "../pages/app/PricingPlans";
import PageNotFound from "../pages/app/PageNotFound";
import AllAdmins from "../pages/app/AllAdmins";
import BulkSMS from "../pages/app/BulkSMS";
import BulkToGroup from "../pages/app/BulkToGroup";
import ClientCreate from "../pages/app/ClientCreate";
import ClientDetails from "../pages/app/ClientDetails";
import ClientList from "../pages/app/ClientList";
import Contacts from "../pages/app/Contacts";
import ContactsGroup from "../pages/app/ContactsGroup";
import Coverage from "../pages/app/Coverage";
import ForgotPassword from "../pages/app/ForgotPassword";
import Login from "../pages/app/Login";
import ManageRoles from "../pages/app/ManageRoles";
import OtpConfirmation from "../pages/app/OtpConfirmation";
import PaymentList from "../pages/app/PaymentList";
import PricingList from "../pages/app/PricingList";
import Pricings from "../pages/app/Pricings";
import Register from "../pages/app/Register";
import SingleSMS from "../pages/app/SingleSMS";
import SmsHistoric from "../pages/app/SmsHistoric";
import SubscribePackage from "../pages/app/SubscribePackage";
import UnAuthorized from "../pages/app/UnAuthorized";
import UpdateClientBalance from "../pages/app/UpdateClientBalance";
import HomePage from "../pages/public/HomePage";
import WhatsappPage from "../pages/app/WhatsappPage";
import { socket } from "../utils/socket";
import TermsAndConditions from "../pages/public/TermsAndConditions";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import { logMessage } from "../utils/logMessage";

interface MainProps {
  isMiniMenu: boolean;
  onToggleMenu: () => void;
}

const App: React.FC = () => {
  const [isMiniMenu, setIsMiniMenu] = useState(false);

  const handleMenuToggle = () => {
    setIsMiniMenu(!isMiniMenu);
  };

  return (
    <Router>
      <AuthProvider>
        <Main isMiniMenu={isMiniMenu} onToggleMenu={handleMenuToggle} />
      </AuthProvider>
    </Router>
  );
};

const Main: React.FC<MainProps> = ({ isMiniMenu, onToggleMenu }) => {
  const location = useLocation();

  const hideNavPaths = [
    "/",
    "/terms-and-conditions",
    "/privacy-policy",
    "/auth",
    "/auth/verification",
    "/register",
    "/auth/reset-password",
    "/auth/forgot-password",
    "/unauthorized",
  ];

  const definedPaths = [
    "/dashboard",
    "/payment-list",
    "/client-list",
    "/client-create",
    "/client-details",
    "/coverage",
    "/manage-pricing",
    "/add-pricing",
    "/packages-whatsapp",
    "/admin",
    "/manage-roles",
    "/contacts",
    "/direct-sms",
    "/send-bulk",
    "/sms-historic",
    "/settings",
    "/whatsapp",
    "/subscribe",
    "/all-pricing",
    "/test",
  ];

  const isMatchingDefinedPath = definedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const is404Page =
    !isMatchingDefinedPath && !hideNavPaths.includes(location.pathname);

  const shouldShowNav = !hideNavPaths.includes(location.pathname) && !is404Page;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={`app ${isMiniMenu ? "mini-menu" : ""}`}>
        {shouldShowNav && (
          <Navigation isMiniMenu={isMiniMenu} onToggleMenu={onToggleMenu} />
        )}
        <Routes>
          {/* public routes  */}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* App Routes */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/payment-list"
            element={
              <AdminRoutes>
                <PaymentList />
              </AdminRoutes>
            }
          />
          <Route
            path="/client-list"
            element={
              <AdminRoutes>
                <ClientList />
              </AdminRoutes>
            }
          />
          <Route
            path="/client-create"
            element={
              <AdminRoutes>
                <ClientCreate />
              </AdminRoutes>
            }
          />
          <Route
            path="/client-details/:userCode"
            element={
              <AdminRoutes>
                <ClientDetails />
              </AdminRoutes>
            }
          />
          <Route
            path="/coverage"
            element={
              <SuperAdminRoutes>
                <Coverage />
              </SuperAdminRoutes>
            }
          />
          <Route
            path="/manage-pricing"
            element={
              <SuperAdminRoutes>
                <PricingList />
              </SuperAdminRoutes>
            }
          />
          <Route
            path="/add-pricing"
            element={
              <SuperAdminRoutes>
                <AddPricing />
              </SuperAdminRoutes>
            }
          />
          <Route
            path="/packages-whatsapp"
            element={
              <AdminRoutes>
                <PricingPlans />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminRoutes>
                <AllAdmins />
              </AdminRoutes>
            }
          />
          <Route
            path="/client-details/:id/balance"
            element={
              <SuperAdminRoutes>
                <UpdateClientBalance />
              </SuperAdminRoutes>
            }
          />
          <Route
            path="/manage-roles"
            element={
              <SuperAdminRoutes>
                <ManageRoles />
              </SuperAdminRoutes>
            }
          />
          <Route path="/auth" element={<Login />} />
          <Route path="/auth/verification" element={<OtpConfirmation />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contacts-create" element={<ContactsCreate />} />
          <Route path="/contacts-group" element={<ContactsGroup />} />
          <Route path="/direct-sms" element={<SingleSMS />} />
          <Route path="/send-bulk" element={<BulkSMS />} />
          <Route path="/send-bulk-group" element={<BulkToGroup />} />
          <Route path="/sms-historic" element={<SmsHistoric />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/whatsapp" element={<WhatsappPage socket={socket} />} />
          <Route path="/subscribe" element={<SubscribePackage />} />
          <Route path="/all-pricing" element={<Pricings />} />
          <Route path="/unauthorized" element={<UnAuthorized />} />

          {/* 404 Not Found Route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Toaster />
      </div>
    </LocalizationProvider>
  );
};

const AdminRoutes: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user, loading } = useAuth();
  logMessage("in it");
  if (loading) {
    logMessage(loading);
    return <div>Loading...</div>;
  }

  if (user.role === "USER") {
    logMessage(user.role);
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

const SuperAdminRoutes: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { user, loading } = useAuth();

  if (loading) {
    logMessage(loading);
    return <div>Loading...</div>;
  }

  if (user.role !== "SUPER_ADMIN") {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default App;
