import React from "react";
import {
  UilArrowCircleLeft,
  UilUser,
  UilMoneyBillStack,
} from "@iconscout/react-unicons";
import { Elements } from "@stripe/react-stripe-js";
import StepperComponent from "../StepperComponent";
import CheckoutForm from "../CheckoutForm";
import { Stripe } from "@stripe/stripe-js";

const steps = [
  { label: "Topup AMount", icon: <UilMoneyBillStack /> },
  { label: "Account Details", icon: <UilUser /> },
];

type TopUpFormProps = {
  currentStep: number;
  handleBack: () => void;
  handleCreatePaymentIntent: () => void;
  amount: number | null;
  setAmount: (amount: number) => void;
  clientSecret: string | undefined;
  stripePromise: Promise<Stripe | null>;
  onSuccess: () => void;
  onBack: () => void;
};

const TopUpForm: React.FC<TopUpFormProps> = ({
  currentStep,
  handleBack,
  handleCreatePaymentIntent,
  amount,
  setAmount,
  clientSecret,
  onSuccess,
  stripePromise,
  onBack,
}) => {
  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  return (
    <div>
      <button onClick={onBack} className="btn text-black border-0 mb-3">
        <UilArrowCircleLeft />
        Back
      </button>
      <div className="card">
        <div className="card-header">Topup Balance</div>
        <div className="card-body">
          <StepperComponent
            steps={steps}
            currentStep={currentStep}
            onNext={handleCreatePaymentIntent}
            onBack={handleBack}
            isLoading={false}
          >
            {currentStep === 0 && (
              <form>
                <div className="mb-3 col-md-6 mx-auto my-3">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    id="amount"
                    value={amount || ""}
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    min="0"
                  />
                </div>
              </form>
            )}
            {currentStep === 1 && clientSecret && amount && (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm handleSuccess={onSuccess} amount={amount} />
              </Elements>
            )}
          </StepperComponent>
        </div>
      </div>
    </div>
  );
};

export default TopUpForm;
