import { useEffect, useState } from "react";
import {
  UilAngleLeftB,
  UilPaperclip,
  UilSetting,
  UilPlus,
} from "@iconscout/react-unicons";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { Client } from "../../models/Client";
import { adminServices } from "../../services/adminService";

type Props = {};

const ClientList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const navigate = useNavigate();

  const handleDelete = async () => {};
  const handleManage = async (client: Client) => {
    setSelectedClient(client);
    navigate(`/client-details/${client.user_code}`);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      await adminServices
        .getClients("sms")
        .then((res: any) => {
          if (res.status === -1) {
            return;
          }
          setUsers(res.data);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };

  const getStatusBadgeClass = (status: any) => {
    switch (status) {
      case "Account_created":
        return "bg-soft-warning text-warning";
      case "Account_active":
        return "bg-soft-success text-success";
      case "Account_desactivated":
        return "bg-soft-danger text-danger";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Client List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                  <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>Print</span>
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-primary" to={"/client-create"}>
                      <UilPlus />
                      <span>Create Client</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div
                    className="page-header-right-open-toggle"
                  >
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th className="wd-30">
                                <div className="btn-group mb-1">
                                  <div className="custom-control custom-checkbox ms-1">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="checkAllCustomer"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="checkAllCustomer"
                                    ></label>
                                  </div>
                                </div>
                              </th>
                              <th>Firstname</th>
                              <th>Lastname</th>
                              <th>Date Created</th>
                              {/* <th>Credit sms</th> */}
                              {/* <th>use api</th> */}
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users &&
                              users.map((user, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <div className="item-checkbox ms-1">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox"
                                          id="checkBox_1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="checkBox_1"
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="text-truncate-1-line">
                                      {user.firstname}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {user.lastname}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {moment(user.updatedAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </div>
                                  </td>
                                  {/* <td>{user.creditSms}</td> */}
                                  {/* <td>{user.date}</td> */}
                                  <td>
                                    <span
                                      className={`badge ${getStatusBadgeClass(
                                        user.status
                                      )}`}
                                    >
                                      {user.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center">
                                      <button
                                        className="btn btn-primary btn-light-brand py-1"
                                        onClick={() => handleManage(user)}
                                      >
                                        <UilSetting />
                                        <span>Manage</span>
                                      </button>
                                      {/* <button
                                        className="btn btn-danger btn-light-brand py-1"
                                        onClick={() => setSelectedClient(user)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                      >
                                        <UilTrashAlt />
                                        <span>Delete</span>
                                      </button> */}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {users?.length === 0 && (
                          <h5 className="text-center my-5">
                            Your client list will appear here
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Client
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Do you really want to delete the client{" "}
                <span className="fw-bold">
                  {selectedClient?.firstname} {selectedClient?.lastname}
                </span>{" "}
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                  disabled={loading}
                  data-bs-dismiss="modal"
                >
                  {loading && <span className="loader"></span>}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ClientList;
