import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { SMS, SMSStatus } from "../../models/SMS";
import { getAllSms } from "../../redux/slices/smsSlice";
import { UilArrowLeft, UilArrowCircleRight } from "@iconscout/react-unicons";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { AppDispatch } from "../../redux/store";

type Props = {};

const SmsHistoric = (props: Props) => {
  const [messages, setMessages] = useState<SMS[] | null>(null);
  const [selectedMessage, setSelectedMessage] = useState<SMS | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [filters, setFilters] = useState<{
    content?: string;
    status?: SMSStatus;
  }>({});

  const dispatch:AppDispatch = useDispatch();

  const fetchSms = async () => {
    try {
      const res: any = await dispatch(
        getAllSms({
          page: currentPage,
          limit: itemsPerPage,
          filters,
        }) 
      ).unwrap();
      if (res.status === -1) {
        setMessages([]);
      } else {
        setMessages(res.data);
        setTotalPages(Math.ceil(res.total / itemsPerPage));
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchSms();
  }, [dispatch, currentPage, itemsPerPage]);

  const getStatusBadgeClass = (status: SMSStatus) => {
    switch (status) {
      case "Pending":
        return "bg-soft-warning text-warning";
      case "Sent":
        return "bg-soft-success text-success";
      case "Failed":
        return "bg-soft-danger text-danger";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };

  const truncateContent = (content: string, maxLength: number) => {
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  const handleRowClick = (message: SMS) => {
    setSelectedMessage(message);
  };

  const handleBackClick = () => {
    setSelectedMessage(null);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="main-content">
              <div className="d-flex p-3 mb-2 w-100 justify-content-end align-items-center">
                <span>Items per page :</span>
                <select
                  name="items"
                  id="items"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="ms-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={-1}>All</option>
                </select>
              </div>
              <div className="row">
                <div className="col-xxl-8">
                  <div className="card stretch stretch-full">
                    {selectedMessage ? (
                      <div>
                        <div className="card-header">
                          <button
                            onClick={handleBackClick}
                            className="btn border-0 bg-transparent"
                          >
                            <UilArrowLeft /> Back
                          </button>
                          <h5 className="card-title text-center">
                            SMS Details
                          </h5>
                        </div>
                        <div className="card-body">
                          <h6>SMS Code: {selectedMessage.code_msg}</h6>
                          <div className="d-flex align-items-center mb-3 gap-2">
                            <h6 className="m-0">SMS Content : </h6>
                            <p className="m-0"> {selectedMessage.smsContent}</p>
                          </div>

                          <h6>Contacts</h6>
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="border-b">
                                  <th scope="row">First Name</th>
                                  <th>Last Name</th>
                                  <th>Email</th>
                                  <th>Phone</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedMessage.contactsJsonParsed &&
                                  selectedMessage.contactsJsonParsed.map(
                                    (contact: any, index: number) => (
                                      <tr key={index}>
                                        <td>{contact.firstname || "N/A"}</td>
                                        <td>{contact.lastname || "N/A"}</td>
                                        <td>{contact.email || "N/A"}</td>
                                        <td>{contact.phone}</td>
                                        <td>
                                          <span
                                            className={`badge ${
                                              contact.status
                                                ? "bg-soft-success text-success"
                                                : "bg-soft-danger text-danger"
                                            }`}
                                          >
                                            {contact.status
                                              ? "Sent"
                                              : "Not Sent"}
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="card-header">
                          <h5 className="card-title">SMS History</h5>
                        </div>
                        <div className="card-body custom-card-action p-0">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="border-b">
                                  <th scope="row">SMS Code</th>
                                  <th>Content</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Scheduled Send Time</th>
                                  <th>Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {messages && messages.length > 0 ? (
                                  messages.map((message: SMS) => (
                                    <tr key={message.id}>
                                      <td>{message.code_msg}</td>
                                      <td>
                                        {truncateContent(
                                          message.smsContent,
                                          20
                                        )}
                                      </td>
                                      <td>
                                        {moment(message.timestamp).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge ${getStatusBadgeClass(
                                            message.status as SMSStatus
                                          )}`}
                                        >
                                          {message.status}
                                        </span>
                                      </td>
                                      <td>
                                        {message.isScheduled
                                          ? moment(message.sendTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td
                                        onClick={() => handleRowClick(message)}
                                      >
                                        <button className="bg-transparent border-0 ms-1">
                                          <UilArrowCircleRight className="text-secondary fs-2" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No SMS records found.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className="card-footer">
                          <ul className="list-unstyled d-flex align-items-center gap-2 mb-0 pagination-common-style">
                            <li>
                              <button
                                className="bg-transparent border-0"
                                onClick={() =>
                                  handlePageChange(Math.max(1, currentPage - 1))
                                }
                              >
                                <UilArrowLeft />
                              </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                              <li key={index}>
                                <button
                                  className={`bg-transparent border-0 ${
                                    index + 1 === currentPage ? "active" : ""
                                  }`}
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              </li>
                            ))}
                            <li>
                              <button
                                className="bg-transparent border-0"
                                onClick={() =>
                                  handlePageChange(
                                    Math.min(totalPages, currentPage + 1)
                                  )
                                }
                              >
                                <UilArrowCircleRight />
                              </button>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SmsHistoric;
