import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { confirmPayment } from "../redux/slices/accountSlice";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";

type CheckoutFormProps = {
  handleSuccess: () => void;
  amount: number;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  handleSuccess,
  amount,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const topUpAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        return;
      }

      setIsLoading(true);

      const response = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/settings",
        },
        redirect: "if_required",
      });
      const error = response.error;
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        toast.error(
          error.message ?? "Oops!. Unexpected error. Please trya gain later"
        );
      } else {
        const paymentIntentDetails = response.paymentIntent;
        const data = {
          amount: paymentIntentDetails.amount,
          id: paymentIntentDetails.id,
          payment_method: paymentIntentDetails.payment_method,
          payment_method_types: paymentIntentDetails.payment_method_types,
          currency: paymentIntentDetails.currency,
          status: paymentIntentDetails.status,
        };
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        dispatch(confirmPayment(data))
          .unwrap()
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success(res.message);
            handleSuccess();
          });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form action="">
      <div className="col-md-8 mx-auto my-3">
        <PaymentElement />
        <button
          type="button"
          disabled={!stripe || loading}
          className="btn btn-primary mt-3 ms-auto w-100"
          onClick={(e: React.FormEvent) => topUpAccount(e)}
        >
          {loading && <span className="loader"></span>}
          Recharge ${amount}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
