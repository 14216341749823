import React, { useEffect, useRef, useState } from "react";
import { UilTimes } from "@iconscout/react-unicons";
import Creatable from "react-select/creatable";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Contact } from "../models/Contacts";
import { whatsappService } from "../services/whatsappService";
import {
  parseCSVFile,
  parseExcelFile,
  parseTextFile,
} from "../utils/fileParsers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { getAllContacts } from "../redux/slices/contactSlice";
import { customStyles, OptionType } from "../pages/app/SingleSMS";
import { logMessage } from "../utils/logMessage";

type WhatsappMessageProps = {
  phoneNumber: string | undefined;
};

const WhatsappMessage: React.FC<WhatsappMessageProps> = ({ phoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadedContacts, setUploadedContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<OptionType[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector((state: RootState) => state.contacts.contacts);

  const handleSubmit = async (values: { messageContent: string }) => {
    if (!phoneNumber) {
      toast.error("Invalid Phone Number");
      return;
    }

    const contactLookup: Record<string, Contact> = contacts.reduce(
      (acc: Record<string, Contact>, contact) => {
        if (contact.phone) {
          acc[contact.phone] = contact; // Add contact to the lookup if it has a phone number
        }
        return acc;
      },
      {} as Record<string, Contact>
    );

    // Format the phone number by removing the '+' sign
    const formattedPhoneNumber = phoneNumber.startsWith("+")
      ? phoneNumber.slice(1)
      : phoneNumber;

    if (isUploaded && !file) {
      toast.error("Please select a file containing your contacts.");
      return;
    }
    if (!isUploaded && selectedContacts.length === 0) {
      toast.error("You need to select contacts.");
      return;
    }

    if (!formik.values.messageContent) {
      toast.error("Please enter the content of message.");
      return;
    }

    const formattedContacts = selectedContacts.map((option: OptionType) => {
      const contact = contactLookup[option.value];
      return {
        firstname: contact?.firstname || "",
        lastname: contact?.lastname || "",
        email: contact?.email || "",
        phone: option.value,
      };
    });
    const toContacts =
      uploadedContacts.length > 0 ? uploadedContacts : formattedContacts;

    try {
      setIsLoading(true);
      let data = {
        phoneNumber: formattedPhoneNumber,
        message: values.messageContent,
        contactsObj: toContacts,
      };

      const response = await whatsappService.sendMessageToContacts(data);

      if (response.status === -1) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        formik.resetForm();
        setUploadedContacts([]);
        setSelectedContacts([]);
        setIsUploaded(false);
      }
    } catch (error) {
      toast.error("An error occurred while sending your message");
      logMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      messageContent: "",
    },
    validationSchema: Yup.object({
      messageContent: Yup.string()
        .max(128, "Message must be at most 128 characters")
        .required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }

    try {
      let data: Contact[] = [];

      if (file.type === "text/csv") {
        data = await parseCSVFile(file);
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        data = await parseExcelFile(file);
      } else if (file.type === "text/plain") {
        data = await parseTextFile(file);
      }

      setUploadedContacts(data);
      setIsUploaded(true);
    } catch (error) {
      console.error("Error parsing file:", error);
    }

    // Clear the file input value to ensure the change event is fired for the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDiscard = () => {
    setUploadedContacts([]);
    setIsUploaded(false);
    setFile(null);
  };

  const transformContactsToOptions = (contacts: Contact[]): any[] => {
    return contacts
      .filter((contact) => contact.phone) // Filter valid contacts
      .map((contact) => ({
        value: contact.phone,
        label: `${contact.firstname ?? "N/A"} - ${contact.phone}`, // Add a space between firstname and lastname
      }));
  };

  const transformedContacts = transformContactsToOptions(contacts ?? []);

  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="mb-4 col-12 align-items-center justify-content-between">
        <h5 className="fw-bold mb-0 me-4">
          <span className="d-block mb-2">
            Select Saved Contacts or Upload a File Containing Them.
          </span>
          <span className="fs-12 fw-normal text-muted text-truncate-1-line">
            Note that the created contacts here will be saved to your contacts
            list.
          </span>
          <p className="text-primary m-0">
            Important : When creating new contacts, add country code before the
            number. Example : +237698765467
          </p>
        </h5>
      </div>
      <div className="card col-md-8 mx-auto mt-3">
        <div className="card-body">
          <div className="step-content">
            <div className="d-flex flex-column gap-2">
              <div className="text-primary fw-bold">
                {uploadedContacts.length !== 0
                  ? "Note : Only uploaded contacts will be considered"
                  : ""}
              </div>
              <Creatable<OptionType, true>
                options={transformedContacts}
                className=" text-dark"
                placeholder="TO"
                styles={customStyles}
                isDisabled={uploadedContacts.length !== 0}
                id="to"
                isMulti
                name="to"
                value={selectedContacts}
                onChange={(option) =>
                  setSelectedContacts(option as OptionType[])
                }
              />
              <h6 className="text-center mb-0">OR</h6>
              <div className="d-flex border align-items-center">
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  className="p-2 w-100"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".csv, .xlsx, .xls, .txt"
                />
                {isUploaded && (
                  <UilTimes
                    className="border me-1 cursor-pointer"
                    onClick={handleDiscard}
                  />
                )}
              </div>
            </div>
            {isUploaded && (
              <p className="text-start text-primary fw-bold">
                {uploadedContacts.length} contacts uploaded
              </p>
            )}
            <div className="items-details-footer mail-action-editor mt-3 border border-top-0 rounded-3">
              <div
                className="p-0 ht-150 border-top position-relative editor-section"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="h-100 border-0" id="mailEditor">
                  <textarea
                    id="message"
                    className="w-100 border border-bottom-0 ht-100"
                    placeholder="Type a message..."
                    required
                    maxLength={128}
                    value={formik.values.messageContent}
                    onChange={(e) =>
                      formik.setFieldValue("messageContent", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 mt-3 justify-content-end">
              <button
                className="btn btn-primary"
                onClick={() => formik.handleSubmit()}
                disabled={
                  isLoading ||
                  !formik.isValid ||
                  (selectedContacts.length === 0 &&
                    uploadedContacts.length === 0)
                }
              >
                {isLoading && <span className="loader"></span>}
                {isLoading ? "Sending..." : "Send Message"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappMessage;
