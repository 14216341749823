import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type LinearProgressWithLabelProps = {
    value:number
}

const LinearProgressWithLabel:React.FC<LinearProgressWithLabelProps> = ({value}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
             value,
            )}%`}</Typography>
          </Box>
        </Box>
      );
}

export default LinearProgressWithLabel