import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilAngleLeftB,
  UilPaperclip,
  UilSetting,
  UilTrashAlt,
  UilEditAlt,
  UilPlus,
} from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import moment from "moment";
import { pricingService } from "../../services/pricingService";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Country } from "../../models/Coverage";
import {
  pricingInitialValues,
  pricingValidationSchema,
} from "../../validation/FormikValues";

type Props = {};

interface OptionType {
  value: string;
  label: string;
}

const PricingList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [pricings, setPricings] = useState<any[]>([]);
  const [selectedPricing, setSelectedPricing] = useState<any>({});
  const [countries, setCountries] = useState<Country[]>([]);
  const animatedComponents = makeAnimated();

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const res = await pricingService.getCountries();
      if (res.status !== -1) {
        setCountries(res.data);
      }
    } catch (error) {
      toast.error(
        "An error occurred while fetching countries. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const transformCountriesToOptions = (countries: Country[]): OptionType[] => {
    return countries.map((country) => ({
      value: country.id,
      label: country.name,
    }));
  };

  const options: OptionType[] = transformCountriesToOptions(countries);

  const calculateResellerPrice = (partnerPrice: number, percentage: number) => {
    return partnerPrice + (partnerPrice * percentage) / 100;
  };

  const handleSelectPricing = (pricing: any) => {
    setSelectedPricing(pricing);
    formik.setValues({
      operatorName: pricing.name,
      countryId: pricing.country?.id ?? "",
      pricingPartner: pricing.pricing?.price_partner ?? 0,
      pricingReseller: pricing.pricing?.price_reseller ?? 0,
      percentageReseller: pricing.pricing?.percentage ?? 0,
    });
  };

  const handleEditPricing = async () => {
    if (!selectedPricing) return;
    setLoading(true);
    try {
      const data = {
        name: formik.values.operatorName.toUpperCase(),
        countryId: formik.values.countryId,
        pricing: {
          price_partner: formik.values.pricingPartner,
          percentage: formik.values.percentageReseller,
        },
      };
      await pricingService
        .editPricing(selectedPricing.operator_code, data)
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          fetchPricings();
        })
        .catch((res: any) => {
          toast.error(res.message);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      toast.error("An error occured during pricing edit");
    }
  };

  const formik = useFormik({
    initialValues: pricingInitialValues,
    validationSchema: pricingValidationSchema,
    onSubmit: handleEditPricing,
  });

  useEffect(() => {
    const resellerPrice = calculateResellerPrice(
      formik.values.pricingPartner,
      formik.values.percentageReseller
    );
    formik.setFieldValue("pricingReseller", resellerPrice);
  }, [formik.values.pricingPartner, formik.values.percentageReseller]);

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "active":
        return "bg-soft-success text-success";
      case "inactive":
        return "bg-soft-danger text-danger";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };

  const fetchPricings = async () => {
    try {
      setLoading(true);
      const res = await pricingService.getPricings();
      if (res.status === -1) {
        toast.error("Failed to fetch pricings");
        return;
      }
      setPricings(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while fetching pricings. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Pricing List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>Print</span>
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-primary" to={"/add-pricing"}>
                      <UilPlus />
                      <span>Add Pricing</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th className="text-nowrap">Country</th>
                              <th className="text-nowrap">Code</th>
                              <th className="text-nowrap">Operator</th>
                              <th className="text-nowrap">Price (EURO)</th>
                              <th className="text-nowrap">
                                Price Reseller (EURO)
                              </th>
                              {/* <th>Rate</th> */}
                              <th className="text-nowrap">Last Update</th>
                              <th className="text-nowrap">Status</th>
                              <th className="text-nowrap">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pricings.length > 0 ? (
                              pricings.map((pricing, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <span className="text-truncate-1-line">
                                      {pricing?.country?.name ?? "N/A"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <span className="text-truncate-1-line">
                                        {pricing?.country?.iso2 ?? "N/A"}(
                                        {pricing?.country?.phone_code ?? "N/A"})
                                      </span>
                                    </div>
                                  </td>
                                  <td>{pricing?.name ?? "N/A"}</td>
                                  <td>
                                    {pricing?.pricing?.price_partner ?? "N/A"}
                                  </td>
                                  <td>
                                    {pricing?.pricing?.price_receller ?? "N/A"}
                                  </td>
                                  {/* <td>
                                    {pricing?.pricing?.price_receller &&
                                    pricing?.pricing?.price_partner
                                      ? (
                                          pricing.pricing.price_receller /
                                          pricing.pricing.price_partner
                                        ).toFixed(2)
                                      : "N/A"}
                                  </td> */}
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {pricing?.createdAt
                                        ? moment(pricing.createdAt).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        : "N/A"}
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge ${getStatusBadgeClass(
                                        pricing?.country?.status
                                          ? "active"
                                          : "inactive"
                                      )}`}
                                    >
                                      {pricing?.country?.status
                                        ? "active"
                                        : "inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center">
                                      <button
                                        className="btn btn-warning btn-light-brand py-1"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editModal"
                                        onClick={() =>
                                          handleSelectPricing(pricing)
                                        }
                                      >
                                        <UilEditAlt />
                                        <span>Edit</span>
                                      </button>
                                      <button
                                        className="btn btn-danger btn-light-brand py-1"
                                        // onClick={() => setSelectedClient(user)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                      >
                                        <UilTrashAlt />
                                        <span>Delete</span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center my-5">
                                  Your pricing list will appear here
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Pricing
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Do you really want to delete the pricing{" "}
                {/* <span className="fw-bold">
                  {selectedClient?.firstname} {selectedClient?.lastname}
                </span>{" "} */}
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  //   onClick={handleDelete}
                  //   disabled={loading}
                  data-bs-dismiss="modal"
                >
                  {/* {loading && <span className="loader"></```tsx
                  {/* {loading && <span className="loader"></span>} */}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*edit modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="editModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Pricing
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="countryId">Country</label>
                      <Select<OptionType>
                        components={animatedComponents}
                        options={options}
                        // defaultValue={selectedPricings}
                        className="form-control border-0 p-0 text-dark"
                        placeholder="Country"
                        id="countryId"
                        name="countryId"
                        value={
                          options.find(
                            (option) => option.value === formik.values.countryId
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "countryId",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.countryId && formik.errors.countryId ? (
                        <div className="text-danger">
                          {formik.errors.countryId}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="pricingPartner">
                        Pricing Partner (EUR)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Pricing Partner"
                        id="pricingPartner"
                        {...formik.getFieldProps("pricingPartner")}
                      />
                      {formik.touched.pricingPartner &&
                      formik.errors.pricingPartner ? (
                        <div className="text-danger">
                          {formik.errors.pricingPartner}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="percentageReseller">
                        Percentage Reseller
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Percentage Reseller"
                        id="percentageReseller"
                        {...formik.getFieldProps("percentageReseller")}
                      />
                      {formik.touched.percentageReseller &&
                      formik.errors.percentageReseller ? (
                        <div className="text-danger">
                          {formik.errors.percentageReseller}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="pricingReseller">
                        Pricing Reseller (EUR)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Pricing Reseller"
                        id="pricingReseller"
                        value={formik.values.pricingReseller}
                        readOnly
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="operatorName">Operator Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Operator Name"
                        id="operatorName"
                        {...formik.getFieldProps("operatorName")}
                      />
                      {formik.touched.operatorName &&
                      formik.errors.operatorName ? (
                        <div className="text-danger">
                          {formik.errors.operatorName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => formik.handleSubmit()}
                  disabled={!selectedPricing || loading}
                >
                  {loading && <span className="loader"></span>}
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default PricingList;
