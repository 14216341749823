import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import DashboardCard from "../../components/DashboardCard";
import {
  UilDollarSignAlt,
  UilCommentAltMessage,
  UilUsersAlt,
  UilReceiptAlt,
} from "@iconscout/react-unicons";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { useDispatch, useSelector } from "react-redux";
import { getAllSms } from "../../redux/slices/smsSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { getAllContacts } from "../../redux/slices/contactSlice";
import { ContactModel } from "../../models/Contacts";
import toast from "react-hot-toast";
import { SMS } from "../../models/SMS";
import LoaderOverlay from "../../components/Loader/LoaderOverlay";
import { RootState } from "../../redux/store";

const Dashboard:React.FC = () => {
  const [contacts, setContacts] = useState<ContactModel[] | null>(null);
  const [Messages, setMessages] = useState<SMS[] | null>(null);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { balance} = useSelector(
    (state: RootState) => state.account
  );

  const fetchContacts = async () => {
    try {
      const res: any = await dispatch(
        getAllContacts() as unknown as AnyAction
      ).unwrap();
      if (res.status === -1) {
        setContacts([]);
      } else {
        setContacts(res);
      }
    } catch (err) {
      // toast.error("Failed to fetch contacts: An error occurred.");
    }
  };
  const fetchSms = async () => {
    try {
      const res: any = await dispatch(
        getAllSms({ page: 1, limit: 10, filters: {} }) as unknown as AnyAction
      ).unwrap();
      if (res.status === -1) {
        setMessages([]);
      } else {
        setMessages(res.data);
      }
    } catch (err) {
      // toast.error("Failed to fetch SMS: An error occurred.");
    }
  };

  const initData = () => {
    setIsLoading(true);
    try {
      fetchSms();
      fetchContacts();
    } catch (error) {
      toast.error(
        "An error occured while fetching your data. Please try again later"
      );
    }
    setIsLoading(false);
  };
  const contactsCount = contacts?.length;
  const smsCount = Messages?.length;
  useEffect(() => {
    initData();
  }, [dispatch]);
  return (
    <>
      <AuthLayout>
        <VerifiedGuard>
          <LoaderOverlay isLoading={loading} />
          <main className="nxl-container">
            <div className="nxl-content">
              <PageHeader title="Dashboard" />
              {/* <!-- [ Main Content ] start --> */}
              <div className="main-content">
                <div className="row">
                  <DashboardCard
                    description="Total SMS Sent"
                    avatar={<UilCommentAltMessage className="text-primary" />}
                    background="primary"
                    counter={smsCount ?? 0}
                    width={70}
                    showProgress={false}
                  />
                  <DashboardCard
                    description="Total Contacts"
                    avatar={<UilUsersAlt className="text-success" />}
                    background="success"
                    counter={contactsCount ?? 0}
                    width={contactsCount ?? 0}
                    showProgress={false}
                  />
                  <DashboardCard
                    description="Amount to Consume"
                    avatar={<UilDollarSignAlt className="text-warning" />}
                    background="warning"
                    counter={`$ ${balance}`}
                    width={0}
                    showProgress={false}
                  />
                  <DashboardCard
                    description="Total Invoices"
                    avatar={<UilReceiptAlt className="text-danger" />}
                    background="danger"
                    counter={0}
                    width={15}
                    showProgress={false}
                  />
                  {/* <PaymentRecords /> */}
                  {/* <Tickets /> */}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </main>
        </VerifiedGuard>
      </AuthLayout>
    </>
  );
};

export default Dashboard;
