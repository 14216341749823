import React, { useState } from "react";
import Footer from "../../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import AuthLayout from "../../components/auth/AuthLayout";

type Props = {};

const slide_img = [
  "https://swiperjs.com/demos/images/nature-1.jpg",
  "https://swiperjs.com/demos/images/nature-2.jpg",
  "https://swiperjs.com/demos/images/nature-3.jpg",
  "https://swiperjs.com/demos/images/nature-4.jpg",
  "https://swiperjs.com/demos/images/nature-5.jpg",
  "https://swiperjs.com/demos/images/nature-6.jpg",
  "https://swiperjs.com/demos/images/nature-7.jpg",
  "https://swiperjs.com/demos/images/nature-8.jpg",
  "https://swiperjs.com/demos/images/nature-9.jpg",
];

const TestPage = (props: Props) => {
  return (
    <AuthLayout>
      <div>
        <main className="nxl-container">
          <div className="nxl-content">
            {/* <!-- [ Main Content ] start --> */}
            <div className="main-content">
              <div className="main-swiper">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={3}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper: any) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  className="mySwiper"
                >
                  {/* using Array */}
                  {slide_img.map((image, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <img src={image} alt="" />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </AuthLayout>
  );
};

export default TestPage;
