import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { Role, rolesPermissions } from "../../data/RolesPermissions";
import { User } from "../../models/User";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface AuthContextType {
  user: User;
  login: (role: Role) => void;
  isAllowed: (path: string) => boolean;
  loading: boolean; // Add loading state
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const [user, setUser] = useState<User>({
    user_code: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    country: "",
    isVerified: false,
    status: "",
    role: currentUser?.role ?? "USER",
  });

  const [loading, setLoading] = useState(true); // Add loading state

  const login = (role: Role) => setUser({ ...user, role: role });

  const isAllowed = (path: string) => {
    const { role } = user;
    const permissions = rolesPermissions[role];
    return (
      permissions.allowedPaths.includes(path) ||
      permissions.allowedPaths.includes("*")
    );
  };

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
    setLoading(false); // Set loading to false after state is updated
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ user, login, isAllowed, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
